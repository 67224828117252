<template>
  <div class="explorer-stats">
    <BaseSpinner
      v-if="isLoading"
    />
    <div v-else-if="stats && stats.count === 0">
      Daným kritériám nevyhovujú žiadne nehnuteľnosti v našej databáze.
    </div>
    <div v-else-if="stats && stats.count" class="stats">
      <div class="output-box">
        Nájdených nehnuteľností: <strong>{{ stats.count }}</strong>
      </div>
      <OutputRugPlot
        label="Cena za m²"
        :value="stats.average.price"
        :formatter="formatCurrency"
        :knobs="stats.raw.price"
        medianTooltip="Medián je stredná hodnota usporiadaného radu hodnôt. Polovica nájdených nehnuteľností má cenu vyššiu, polovica nižšiu. Hodnoty vedľa mediánu vyjadrujú 5. a 95. percentil. Cena 90% nájdených nehnuteľností spadá do tohto intervalu. Kobercový graf ilustruje distribúciu ceny jednotlivých nehnuteľností naprieč daným intervalom."
      />
      <OutputRugPlot
        label="Plocha"
        :value="stats.average.area"
        :formatter="(v) => `${formatNumber(v)} m²`"
        :knobs="stats.raw.area"
        medianTooltip="Medián je stredná hodnota usporiadaného radu hodnôt. Polovica nájdených nehnuteľností má rozlohu väčšiu, polovica menšiu. Hodnoty vedľa mediánu vyjadrujú 5. a 95. percentil. Rozloha 90% nájdených nehnuteľností spadá do tohto intervalu. Kobercový graf ilustruje distribúciu rozlohy jednotlivých nehnuteľností naprieč daným intervalom."
      />
      <OutputStacks
        v-if="['byt', 'dom'].includes(sector)"
        label="Stav"
        :groups="stats.distrib.condition"
      />
      <OutputStacks
        v-if="['byt', 'dom'].includes(sector)"
        label="Počet izieb"
        :groups="stats.distrib.type"
      />

    </div>
    <div v-else class="stats">
      Pre zobrazenie štatistík vyberte priestor na mape.
    </div>
  </div>
</template>

<script>
import formatNumber from '@/util/formatNumber';
import formatCurrency from '@/util/formatCurrency';

import OutputRugPlot from '@/components/OutputRugPlot.vue';
import OutputStacks from '@/components/OutputStacks.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';

export default {
  name: 'ExplorerStats',
  components: {
    OutputRugPlot,
    OutputStacks,
    BaseSpinner,
  },
  props: {
    stats: Object,
    isLoading: Boolean,
    sector: String,
  },
  data() {
    return {
      formatNumber,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped>
.output-box {
  color: $gray-300;
  background-color: $gray-800;
  border-radius: $border-radius;
  padding: 10px;
  margin-bottom: 1rem;
}
</style>
