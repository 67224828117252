<template>
  <div class="map-filter">
    <BaseButton
      @click="isOpen = !isOpen"
    >
      ⚙️
    </BaseButton>
    <div
      v-if="isOpen"
      class="filter-content"
    >
      <div
        class="row -gutter-half"
      >
        <FormGroup label="Sektor" class="col -w12">
          <InputOptions
            v-model="value.sector"
            :options="optionsSector"
          />
        </FormGroup>
        <FormGroup label="Neaktuálne inzeráty" class="col -w12">
          <InputOptions
            v-model="value.delisted"
            :options="optionsDelisted"
          />
        </FormGroup>
        <FormGroup label="Vek inzerátov" class="col -w12">
          <InputOptions
            v-model.number="value.age"
            :options="optionsAge"
          />
        </FormGroup>

        <FormGroup v-if="optionsCondition.length" label="Stav" class="col -w12">
          <InputOptions
            v-model="value.condition"
            :options="optionsCondition"
            multiselect
          />
        </FormGroup>
        <FormGroup v-if="optionsType.length" label="Počet izieb" class="col -w12">
          <InputOptions
            v-model="value.type"
            :options="optionsType"
            multiselect
          />
        </FormGroup>
        <FormGroup label="Cena" class="col -w12">
          <InputRange
            v-model="value.price"
            :max="1000000"
            :step="1000"
          />
        </FormGroup>
        <FormGroup label="Plocha" class="col -w12">
          <InputRange
            v-model="value.area"
            :max="value.sector === 'pozemok' ? 1000 : 250"
          />
        </FormGroup>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import InputOptions from '@/components/InputOptions.vue';
import InputRange from '@/components/InputRange.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'ExplorerFilter',
  components: {
    FormGroup,
    InputOptions,
    InputRange,
    BaseButton,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    optionsSector: () => [
      { label: 'Byty', value: 'byt' },
      { label: 'Domy', value: 'dom' },
      { label: 'Pozemky', value: 'pozemok' },
    ],
    optionsCondition() {
      if (this.value.sector === 'byt' || this.value.sector === 'dom') {
        return [
          { label: 'Novostavba', value: 'New' },
          { label: 'Pôvodný stav', value: 'Original' },
          { label: 'Kompletná rekonštrukcia', value: 'Reconstruction partial' },
          { label: 'Čiastočná rekonštrukcia', value: 'Reconstruction complete' },
        ];
      }
      return [];
    },
    optionsType() {
      if (this.value.sector === 'byt' || this.value.sector === 'dom') {
        return [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5+', value: '5+' },
        ];
      }
      return [];
    },
    optionsAge: () => [
      { label: '3 m.', value: '3' },
      { label: '6 m.', value: '6' },
      { label: '9 m.', value: '9' },
      { label: 'rok', value: '12' },
    ],
    optionsDelisted: () => [
      { label: 'Zobraziť', value: 'true' },
      { label: 'Skryť', value: 'false' },
    ],
  },
  watch: {
    value: {
      handler(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-content {
  margin-top: 10px;
  width: 20rem;
  padding: 1rem;
  border-radius: $border-radius;
  background-color: $gray-700;
}
</style>
