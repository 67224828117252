<template>
  <vue-slider
    v-model="valueLocal"
    :min="0"
    :max="max"
    :interval="step"
    :processStyle="{ backgroundColor: '#09FBD2' }"
    contained
    :tooltipFormatter="(v) => v.toLocaleString('sk-SK')"
    @change="handleChange"
  ></vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import debounce from 'lodash/debounce';

export default {
  name: 'InputSlider',
  components: {
    VueSlider,
  },
  props: {
    value: Object,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 1000000,
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      valueLocal: [
        this.value.from,
        this.value.to,
      ],
    };
  },
  methods: {
    handleChange: debounce(function handleChange() {
      this.updateChange();
    }, 300),
    updateChange() {
      const [from, to] = this.valueLocal;
      this.$emit('input', {
        from,
        to,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
