<template>
  <div class="form-group">
    <div class="label">{{ label }}</div>
    <div class="content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    label: String,
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.label {
  @include overline;
  text-align: left;
  margin-bottom: 0.25rem;
}
</style>
