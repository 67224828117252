<template>
  <div
    class="base-spinner"
    :style="cssStyles"
  ></div>
</template>

<script>
export default {
  name: 'BaseSpinner',
  props: {
    size: {
      type: Number,
      default: 48,
    },
  },
  computed: {
    cssStyles() {
      return {
        fontSize: `${this.size}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-spinner {
  display: block;
  width: 1em;
  height: 1em;
  margin: 2rem auto;

  border-radius: 50%;
  border: 0.125em solid $gray-500;
  border-right-color: transparent !important;
  animation: spinner-border 1.2s linear infinite;
}

@keyframes spinner-border {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
</style>
