<template>
  <div class="explorer-tabs">
    <div
      v-for="option in options"
      :key="option.value"
      :class="{
        'tab': true,
        checked: value === option.value,
      }"
      @click="$emit('input', option.value)"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path :d="option.icon" fill="currentColor"/></svg>
      <span>{{ option.label }}</span>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      options: [
        { label: 'Štatistika', value: 'stats', icon: 'M22.8002 22.8H1.2002V20.4H22.8002V22.8ZM7.20019 9.60001H2.4002V18H7.20019V9.60001ZM14.4002 1.20001H9.60019V18H14.4002V1.20001ZM21.6002 4.80001H16.8002V18H21.6002V4.80001Z' },
        { label: 'Inzeráty', value: 'propslist', icon: 'M3 4V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V4H3ZM17 17H5V16H17V17ZM19 15H5V14H19V15ZM19 13H5V12H19V13ZM19 11H5V6H19V11Z' },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.explorer-tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $gray-700;
  .tab {
    padding: 0.5rem 0.75rem;
    color: $gray-500;
    border-bottom: 1px solid $gray-700;
    cursor: pointer;
    margin-bottom: -1px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    transition: all .3s;
    svg {
      margin-right: 0.5rem;
    }
    &.checked {
      color: $primary;
      border-bottom-color: $primary;
    }
  }
}
</style>
