<template>
  <div class="explorer">
    <div class="explorer-main">
      <ExplorerMap
        class="explorer-map"
        @update-selection="handleSelectionUpdate"
        @update-bounds="handleBoundsUpdate"
        @select-property="selectedProperties = $event"
        :properties="properties"
        data-tour="map"
      />
      <ExplorerFilter
        v-model="filter"
        data-tour="filter"
        class="explorer-filter"
      />
    </div>
    <div
      class="explorer-sidebar"
    >
      <ExplorerTabs
        v-model="activeTab"
      />
      <div class="explorer-sidebar-inner">
        <ExplorerStats
          v-if="activeTab === 'stats'"
          :stats="stats"
          :isLoading="isLoading"
          :sector="filter.sector"
          data-tour="stats"
        />
        <ExplorerPropsList
          v-if="activeTab === 'propslist'"
          class="explorer-propslist"
          :isLoading="isLoading"
          :properties="selectedPropertiesData"
        />
      </div>
    </div>
    <v-tour
      name="explorerTour"
      :steps="tourSteps"
      :options="{
        labels: {
          buttonSkip: 'Ukončiť',
          buttonPrevious: '« Naspäť',
          buttonNext: 'Ďalej »',
          buttonStop: 'Hotovo',
        }
      }"
      :callbacks="{
        onSkip: handleTourFinish,
        onFinish: handleTourFinish,
      }"
    ></v-tour>
  </div>
</template>

<script>
import api from '@/api';

import ExplorerMap from '@/components/ExplorerMap.vue';
import ExplorerFilter from '@/components/ExplorerFilter.vue';
import ExplorerTabs from '@/components/ExplorerTabs.vue';
import ExplorerStats from '@/components/ExplorerStats.vue';
import ExplorerPropsList from '@/components/ExplorerPropsList.vue';

export default {
  components: {
    ExplorerMap,
    ExplorerFilter,
    ExplorerTabs,
    ExplorerStats,
    ExplorerPropsList,
  },
  data() {
    return {
      isLoading: false,
      stats: null,
      properties: null,
      geoSelection: null,
      geoBounds: null,
      selectedProperties: [],
      selectedPropertiesData: [],

      activeTab: 'stats',
      filter: {
        sector: 'byt',
        age: '12',
        delisted: 'true',
        condition: [],
        type: [],
        price: {
          from: 0,
          to: 1000000,
        },
        area: {
          from: 0,
          to: 250,
        },
      },
      tourSteps: [
        {
          target: '[data-tour="map"]',
          content: 'Na mape sa zobrazujú nehnuteľnosti, ktoré boli na predaj za posledných 6 mesiacov, so svojimi poslednými uvádzanými cenami.',
          params: { placement: 'right' },
        },
        {
          target: '.mapbox-gl-draw_polygon',
          content: 'Pokiaľ nie ste príliš odzoomovaný, tak načítavame všetky nehnuteľnosti, ktoré sa zmestia na mapu. Môžete však nakresliť mnohouholník alebo kruh a načítať iba nehnuteľnosti v ňom.',
          params: { placement: 'left' },
        },
        {
          target: '[data-tour="filter"]',
          content: 'Použite filtre a vyfiltrujte iba to, čo vás zaujíma.',
          params: { placement: 'left' },
        },
        {
          target: '[data-tour="stats"]',
          content: 'Štatistiky sa vyrátavajú zo všetkých aktuálne zobrazených nehnuteľností.',
          params: { placement: 'left' },
        },

      ],
    };
  },
  methods: {
    handleSelectionUpdate(payload) {
      const hasPolygon = payload.features.length;
      if (hasPolygon) {
        this.geoSelection = payload;
      } else {
        this.geoSelection = null;
        this.properties = null;
        this.stats = null;
      }
      this.fetchData();
    },
    handleBoundsUpdate(payload) {
      this.geoBounds = payload;
      if (!this.geoSelection) {
        this.fetchData();
      }
    },
    handleFilterUpdate() {
      this.fetchData();
    },
    getApiPayload() {
      const {
        sector, condition, type, area, price, delisted, age,
      } = this.filter;
      const filter = {};
      if (sector) {
        filter.sector = sector;
      }
      if (condition.length && ['byt', 'dom'].includes(sector)) {
        filter.condition = condition;
      }
      if (type.length && ['byt', 'dom'].includes(sector)) {
        filter.type = type;
      }
      if (area) {
        filter.area = area;
      }
      if (price) {
        filter.price = price;
      }
      if (delisted) {
        filter.delisted = Boolean(delisted);
      }
      if (age) {
        filter.age = Number(age);
      }

      return {
        filter,
        geojson: this.geoSelection || this.geoBounds,
      };
    },
    fetchData() {
      if (this.geoSelection || this.geoBounds) {
        this.fetchStats();
        this.fetchProps();
      }
    },
    async fetchStats() {
      this.activeTab = 'stats';
      this.isLoading = true;
      try {
        const response = await api.post('/v1/geo/stats', this.getApiPayload());
        const { data } = response;
        this.stats = data;
      } catch (e) {
        if (e.message === 'Request failed with status code 429') {
          this.$toasted.error('Chyba 429: Spomaľ, kovboj!');
        }
      } finally {
        this.isLoading = false;
      }
    },
    async fetchProps() {
      const response = await api.post('/v1/geo/props', this.getApiPayload());
      const { data } = response;
      this.properties = data;
    },
    async fetchPropsData() {
      this.activeTab = 'propslist';
      this.isLoading = true;
      const selectedPropertiesString = this.selectedProperties.join(',');
      const response = await api.get(`/v1/geo/props/${selectedPropertiesString}`);
      this.selectedPropertiesData = response.data;
      this.isLoading = false;
    },
    handleTourFinish() {
      this.$store.commit('setTourFinished', true);
    },
  },
  watch: {
    filter: {
      handler() {
        this.handleFilterUpdate();
      },
      deep: true,
    },
    selectedProperties() {
      this.fetchPropsData();
    },
  },
  mounted() {
    const { tourFinished } = this.$store.state;
    if (!tourFinished) {
      this.$tours.explorerTour.start();
    }
  },
};
</script>

<style lang="scss" scoped>
.explorer {
  .explorer-map {
    height: 400px;
  }
  .explorer-sidebar {
    hr {
      border-color: $gray-500;
      border-bottom: 0;
    }
    .explorer-sidebar-inner {
      padding: 1rem;
    }
  }
  @media screen and (min-width: 768px) {
    height: 100%;
    width: 100%;
    display: flex;
    .explorer-main {
      height: initial;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      flex: 1;
      position: relative;
    }
    .explorer-map {
      flex: 1;
    }
    .explorer-filter {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    .explorer-sidebar {
      flex: 0 0 22rem;
      height: calc(100vh - 85px - 61px);
      display: flex;
      flex-direction: column;
      .explorer-sidebar-inner {
        overflow-y: scroll;
        flex: 1;
      }
    }
  }
}

</style>
