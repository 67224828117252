<template>
  <div class="base-tooltip" v-tooltip="tooltip">
    ?
  </div>
</template>

<script>
export default {
  props: {
    tooltip: String,
  },
};
</script>

<style lang="scss" scoped>
.base-tooltip {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: $gray-500;
  color: $gray-900;
  font-weight: 700;
  font-size: 8px;
  border-radius: 50%;
}
</style>
