import router from '@/router';

/* eslint-disable no-undef, no-underscore-dangle */
class FeedbackControl {
  onAdd(mapObject) {
    this._map = mapObject;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl mapboxgl-ctrl-feedback';
    this._btn.type = 'button';
    this._btn.textContent = 'Pošlite nám tip na zlepšenie »';
    this._btn.onclick = () => {
      router.push({ name: 'Contact' });
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group';
    this._container.appendChild(this._btn);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
/* eslint-enable */

export default FeedbackControl;
