<template>
  <div class="output-stacks">
    <div class="output-label">{{ label }}</div>
    <div class="output-chart">
      <div
        v-for="(group, i) in groups"
        :key="i"
        :style="getStyle(group, i)"
        class="group"
        @mouseenter="legendDisplay(group)"
        @mouseleave="legendHide()"
      />
    </div>
    <div class="output-legend" :class="{ 'active': isHovering }">
      <div class="name">{{ legend.name }}</div>
      <div class="count">{{ legend.count }}</div>
    </div>
  </div>
</template>

<script>
const colors = [
  '#10F6D3',
  '#27E6D8',
  '#43D1DE',
  '#67B7E5',
  '#81A4EB',
  '#9794EF',
  '#B082F4',
];

export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    groups: {
      type: Array,
    },
  },
  data() {
    return {
      isHovering: false,
      legend: {
        name: '',
        count: '',
      },
    };
  },
  computed: {
    totalItemCount() {
      return this.groups.reduce((acc, cv) => acc + cv.count, 0);
    },
  },
  methods: {
    getStyle(group, i) {
      const width = group.count / this.totalItemCount;
      const colorIndex = i % colors.length;
      return {
        width: `${width * 100}%`,
        backgroundColor: colors[colorIndex],
      };
    },
    translate(text) {
      switch (text) {
        case 'New': return 'Novostavba';
        case 'Reconstruction complete': return 'Kompletná rekonštrukcia';
        case 'Reconstruction partial': return 'Čiastočná rekonštrukcia';
        case 'Original': return 'Pôvodný stav';
        case '1': return '1-izbový';
        case '2': return '2-izbový';
        case '3': return '3-izbový';
        case '4': return '4-izbový';
        case '5+': return '5 a viac izbový';
        case 'Other': return 'Iné';
        default: return text;
      }
    },
    legendDisplay(group) {
      this.isHovering = true;
      this.legend.name = this.translate(group.value);

      const fraction = group.count / this.totalItemCount;
      const outOf = `${group.count}/${this.totalItemCount}`;
      this.legend.count = `${Math.round(fraction * 100)} % (${outOf})`;
    },
    legendHide() {
      this.isHovering = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.output-stacks {
  margin-bottom: 10px;
}

.output-label {
  @include overline;
}

.output-chart {
  display: flex;
  border-radius: $border-radius;
  overflow: hidden;
  .group {
    height: 30px;
    cursor: help;
    transition: opacity .3s;
  }
  &:hover .group:not(:hover) {
    opacity: 0.4;
  }
}

.output-legend {
  display: flex;
  justify-content: space-between;
  color: $gray-500;
  text-transform: uppercase;
  font-size: 12px;
  padding-top: 3px;
  min-height: 20px;
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}

</style>
