var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"explorer"},[_c('div',{staticClass:"explorer-main"},[_c('ExplorerMap',{staticClass:"explorer-map",attrs:{"properties":_vm.properties,"data-tour":"map"},on:{"update-selection":_vm.handleSelectionUpdate,"update-bounds":_vm.handleBoundsUpdate,"select-property":function($event){_vm.selectedProperties = $event}}}),_c('ExplorerFilter',{staticClass:"explorer-filter",attrs:{"data-tour":"filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{staticClass:"explorer-sidebar"},[_c('ExplorerTabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}),_c('div',{staticClass:"explorer-sidebar-inner"},[(_vm.activeTab === 'stats')?_c('ExplorerStats',{attrs:{"stats":_vm.stats,"isLoading":_vm.isLoading,"sector":_vm.filter.sector,"data-tour":"stats"}}):_vm._e(),(_vm.activeTab === 'propslist')?_c('ExplorerPropsList',{staticClass:"explorer-propslist",attrs:{"isLoading":_vm.isLoading,"properties":_vm.selectedPropertiesData}}):_vm._e()],1)],1),_c('v-tour',{attrs:{"name":"explorerTour","steps":_vm.tourSteps,"options":{
      labels: {
        buttonSkip: 'Ukončiť',
        buttonPrevious: '« Naspäť',
        buttonNext: 'Ďalej »',
        buttonStop: 'Hotovo',
      }
    },"callbacks":{
      onSkip: _vm.handleTourFinish,
      onFinish: _vm.handleTourFinish,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }