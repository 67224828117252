<template>
  <div class="explorer-props-list">
    <BaseSpinner
      v-if="isLoading"
    />
    <div v-else-if="properties && properties.length === 0">
      Nemáte vybrané žiadne inzeráty
    </div>
    <div
      v-else-if="properties && properties.length"
      class="list"
    >
      <PropertyCard
        v-for="property in properties"
        :key="property.id"
        :property="property"
      />
    </div>
  </div>

</template>

<script>
import PropertyCard from '@/components/PropertyCard.vue';
import BaseSpinner from '@/components/BaseSpinner.vue';

export default {
  components: {
    PropertyCard,
    BaseSpinner,
  },
  props: {
    properties: Array,
    isLoading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.explorer-props-list {
  overflow-x: auto;
  .list {
    > * + * {
      margin-top: 10px;
    }
  }
}
</style>
