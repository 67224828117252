<template>
  <div class="map-wrapper">
    <div ref="map" style="height:100%"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { DragCircleMode, DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import ExtendDrawBar from '@/util/ExtendDrawBar';
import FeedbackControl from '@/util/FeedbackControl';

const moveCoordinate = (coordinate, amount) => {
  const difference = amount * 0.00005;
  return coordinate + difference;
};

const convertToMultiPolygon = (geojson) => {
  if (geojson.features.length > 1) {
    const mapped = geojson.features.map((feature) => [feature.geometry.coordinates[0]]);
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: mapped,
            type: 'MultiPolygon',
          },
        },
      ],
    };
  }
  return geojson;
};

const convertBoundsToPolygon = (bounds) => {
  const coordinates = [[
    [bounds.getWest(), bounds.getNorth()],
    [bounds.getEast(), bounds.getNorth()],
    [bounds.getEast(), bounds.getSouth()],
    [bounds.getWest(), bounds.getSouth()],
    [bounds.getWest(), bounds.getNorth()],
  ]];
  return {
    type: 'FeaturesCollection',
    features: [
      {
        geometry: {
          coordinates,
          type: 'Polygon',
          features: {},
        },
        type: 'Feature',
      },
    ],
  };
};

const mapboxToken = process.env.VUE_APP_MAPBOX_TOKEN;

let map = null;
let draw = null;

export default {
  data() {
    return {
      map: null,
      draw: null,
    };
  },
  props: {
    properties: Object,
  },
  computed: {
    propertiesJittered() {
      if (!this.properties) {
        return this.properties;
      }

      const processedCoordinates = [];
      const jittered = [...this.properties.features];
      jittered.forEach((point, index) => {
        const { coordinates } = point.geometry;
        const coordinatesAsString = coordinates.join(',');
        processedCoordinates.push(coordinatesAsString);
        const duplicates = processedCoordinates.filter((v) => v === coordinatesAsString);

        if (duplicates.length > 1) {
          const [lng, lat] = coordinates;
          const newLat = moveCoordinate(lat, duplicates.length);

          jittered[index].geometry.coordinates = [
            lng,
            newLat,
          ];
        }
      });
      return {
        ...this.properties,
        features: jittered,
      };
    },
  },
  methods: {
    createMap() {
      mapboxgl.accessToken = mapboxToken;
      map = new mapboxgl.Map({
        container: this.$refs.map,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [17.124, 48.1465],
        zoom: 15,
        logoPosition: 'bottom-right',
      });
      const navControl = new mapboxgl.NavigationControl();
      const geoCoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: 'sk',
        mapboxgl,
      });
      draw = new MapboxDraw({
        modes: Object.assign(MapboxDraw.modes, {
          drag_circle: DragCircleMode,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode,
        }),
        userProperties: true,
        displayControlsDefault: false,
        controls: {
          trash: true,
          polygon: true,
        },
      });

      // Adding radius, see https://github.com/mapbox/mapbox-gl-draw/issues/874
      const startRadius = () => draw.changeMode('drag_circle');
      const drawBar = new ExtendDrawBar({
        draw,
        buttons: [
          {
            on: 'click',
            action: startRadius,
            classes: ['mapbox-gl-draw_radius'],
          },
        ],
      });

      const feedbackControl = new FeedbackControl();

      map.addControl(geoCoder);
      map.addControl(navControl);
      map.addControl(drawBar);
      map.addControl(feedbackControl, 'bottom-left');

      map.on('draw.create', this.updateSelection);
      map.on('draw.update', this.updateSelection);
      map.on('draw.delete', this.updateSelection);
      map.on('dragend', this.updateBounds);
      map.on('zoomend', this.updateBounds);

      map.on('load', () => {
        this.createMapPropsLayer();
        this.updateBounds();
      });
    },
    createMapPropsLayer() {
      map.addSource('propertyMarkers', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
      map.addLayer({
        id: 'propertyMarkers',
        type: 'circle',
        source: 'propertyMarkers',
        paint: {
          'circle-color': '#0CF9D3',
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#ffffff',
        },
      });

      map.on('click', 'propertyMarkers', (e) => {
        console.log(e.features);
        const ids = e.features.map((feature) => feature.properties.id);
        this.$emit('select-property', ids);
        /*
        const coordinates = e.features[0].geometry.coordinates.slice();

        const propertyId = e.features[0].properties.id;
        const response = await api.get(`/v1/geo/props/${propertyId}`);
        const properties = response.data;

        const output = [
          properties.type ? `<strong>Typ:</strong> ${properties.type}` : null,
          properties.address ? `<strong>Adresa:</strong> ${properties.address}` : null,
          properties.area ? `<strong>Plocha:</strong> ${properties.area} m²` : null,
          properties.pricetotal ? `<strong>Celková cena:</strong> ${formatCurrency(properties.pricetotal)}` : null,
          properties.condition ? `<strong>Stav:</strong> ${formatNumber(properties.condition)}` : null,
          properties.floor ? `<strong>Poschodie:</strong> ${properties.floor}` : null,
        ];

        const html = output.filter(Boolean).join('<br>');

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(html).addTo(map);
        */
      });

      map.on('mouseenter', 'propertyMarkers', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'propertyMarkers', () => {
        map.getCanvas().style.cursor = '';
      });
    },
    startRadius() {
      draw.changeMode('drag_circle');
    },
    updateSelection() {
      const converted = convertToMultiPolygon(draw.getAll());
      this.$emit('update-selection', converted);
    },
    updateBounds() {
      if (map.getZoom() > 12) {
        const bounds = map.getBounds();
        const polygon = convertBoundsToPolygon(bounds);
        this.$emit('update-bounds', polygon);
      }
    },
  },
  mounted() {
    this.createMap();
  },
  watch: {
    properties(newValue) {
      if (newValue) {
        map.getSource('propertyMarkers').setData(newValue);
      } else {
        map.getSource('propertyMarkers').setData({
          type: 'FeatureCollection',
          features: [],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  height: 100%;
  position: relative;
}
</style>

<style lang="scss">
.mapboxgl-popup {
  max-width: 400px;
  color: black;
  z-index: 10;
}
.mapbox-gl-draw_radius {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 15a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 100-14 7 7 0 000 14z' fill='%23000'/%3E%3Ccircle cx='10' cy='10' r='1' fill='%23000'/%3E%3Crect x='12' y='9' width='4' height='2' rx='1' fill='%23000'/%3E%3C/svg%3E");
}
button.mapboxgl-ctrl-feedback {
  background-color: white;
  width: auto;
  padding: 0 0.75rem;
  border-radius: 5px;
}
</style>
