<template>
  <div class="property-card">
    <ul>
      <li><strong>Typ</strong> {{ property.type }}</li>
      <li><strong>Adresa</strong> {{ property.address }}</li>
      <li><strong>Plocha</strong> {{ property.area }} m²</li>
      <li><strong>Stav</strong> {{ property.condition }}</li>
      <li><strong>Cena</strong> {{ formatCurrency(property.pricetotal) }}</li>
      <li><strong>Cena / m²</strong> {{ formatCurrency(property.pricearea) }}</li>
      <li><strong>Agent</strong> {{ property.agent }}</li>
      <li v-if="property.phone"><strong>Agent</strong> {{ property.phone }}</li>
      <li v-if="!property.delisted"><strong>Inzerát je aktívny</strong></li>
    </ul>
  </div>
</template>

<script>
import formatCurrency from '@/util/formatCurrency';

export default {
  name: 'PropertyCard',
  props: {
    property: Object,
  },
  data() {
    return {
      formatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped>
.property-card {
  background-color: $gray-800;
  border-radius: $border-radius;
  padding: 10px;
  flex: 0 0 300px;
  min-height: 140px;
  ul {
    @include list-unstyled;
    font-size: 12px;
  }
}
</style>
