<template>
  <div class="row -gutter-half">
    <label
      v-for="option in options"
      :key="option.value"
      class="input-option col"
    >
      <input
        :type="multiselect ? 'checkbox' : 'radio'"
        :name="name"
        :value="option.value"
        :checked="isChecked(option.value)"
        class="input-option-input"
        @change="handleChange"
      >
      <div
        class="input-option-button"
        :class="{ checked: isChecked(option.value) }"
      >
        {{ option.label }}
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputOptions',
  props: {
    value: [Array, String, Boolean, Number],
    options: Array,
    multiselect: Boolean,
  },
  data() {
    return {
      localValue: this.multiselect ? [...this.value] : this.value,
    };
  },
  computed: {
    name() {
      const uid = Math.round(Math.random() * 1000);
      return `input-options-uid-${uid}`;
    },
    isChecked: (vm) => (value) => {
      if (vm.multiselect) {
        return vm.localValue.includes(value);
      }
      return vm.localValue === value;
    },
  },
  methods: {
    handleChange(event) {
      if (this.multiselect) {
        this.handleChangeMultiselect(event);
      } else {
        this.handleChangeSingle(event);
      }
    },
    handleChangeMultiselect(event) {
      const { checked, value } = event.target;
      if (checked) {
        this.localValue.push(value);
      } else {
        const index = this.localValue.indexOf(value);
        if (index > -1) {
          this.localValue.splice(index, 1);
        }
      }
    },
    handleChangeSingle(event) {
      this.localValue = event.target.value;
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-option-input {
  display: none;
}

.input-option-button {
  @include styled-input;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  background-color: $gray-800;
  &.checked {
    background-color: $primary;
    color: $gray-900;
  }
}
</style>
