<template>
  <div class="output-rug-plot">
    <div class="output-label">{{ label }}</div>
    <div class="output-chart">
      <div class="main">
        <div class="labels">
          <div class="min">5%</div>
          <div class="median">
            Medián
            <BaseTooltip
              v-if="medianTooltip"
              :tooltip="medianTooltip"
            />
          </div>
          <div class="max">95%</div>
        </div>
        <div class="values">
          <div class="min">{{ formatter(min) }}</div>
          <div class="median">{{ formatter(value) }}</div>
          <div class="max">{{ formatter(max) }}</div>
        </div>
      </div>
      <div class="knobs">
        <div class="knobsinner">
          <div
            v-for="(knob, i) in knobPositions"
            :key="i"
            class="knob"
            :style="{ left: knob }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTooltip from '@/components/BaseTooltip.vue';

export default {
  components: {
    BaseTooltip,
  },
  props: {
    label: {
      type: String,
    },
    medianTooltip: {
      type: String,
    },
    value: {
      type: Number,
    },
    knobs: {
      type: Array,
    },
    formatter: {
      type: Function,
      default: (v) => v,
    },
  },
  computed: {
    knobsOrdered() {
      const ordered = [...this.knobs];
      ordered.sort((a, b) => a - b);
      return ordered;
    },
    knobsReduced() {
      const { length } = this.knobsOrdered;
      const start = Math.floor(length * 0.05);
      const end = Math.ceil(length * 0.95);
      return this.knobsOrdered.slice(start, end);
    },
    knobPositions() {
      const { min, max } = this;
      return this.knobsReduced.map((knob) => {
        const ratio = (knob - min) / (max - min);
        return `${ratio * 100}%`;
      });
    },
    min() {
      return this.knobsReduced[0];
    },
    max() {
      return this.knobsReduced[this.knobsReduced.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.output-label {
  @include overline;
}

.output-chart {
  background-color: $gray-800;
  border-radius: $border-radius;
  margin-bottom: 1rem;
  .main {
    padding: 4px 8px;
  }
  .labels,
  .values {
    display: flex;
    align-items: center;
  }
  .labels {
    color: $gray-500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  .values {
    color: $gray-300;
    font-size: 12px;
    .median {
      font-weight: 700;
      font-size: 24px;
    }
  }
  .median {
    flex: 1;
    text-align: center;
  }
  .knobs {
    padding: 0 2px;
    border-top: 1px solid $gray-900;
  }
  .knobsinner {
    height: 20px;
    position: relative;
  }
  .knob {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $gray-300;
    opacity: 0.5;
  }
}
</style>
